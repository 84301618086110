import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { NavLink } from "react-router-dom";

//styles
import s from "./FooterMenu.module.scss";
import breakpoints from "../../../../../styles/vars";

//svg
import { arrowSvg } from "../../../../ui/Svgs/arrowSvg";

//hooks
import { useAppSelector } from "../../../../../hooks/redux";

//routes
import { paths, pathFC } from "../../../../../App/Routes/paths";

//utils
import { IncludeMassElement } from "../../../../../utils/generals/IncludeMassElement";

//db
import { footerMenuCategoryLink } from "../../../../../db/Footer/Footer";

const FooterMenu = () => {
  const lg = useMediaQuery({ maxWidth: breakpoints.lg });

  const { dataRawCategoriesBack } = useAppSelector(
    (state) => state.rawCategories
  );

  const gender = useAppSelector((state) => state.app.gender);

  const [categoriesBack, setCategoriesBack] = useState<any>([]);

  const [activeDrop, setActiveDrop] = useState<string>("");

  useEffect(() => {
    dataRawCategoriesBack?.[gender]?.children?.length > 0 &&
      setCategoriesBack([
        ...dataRawCategoriesBack?.[gender]?.children.map((item: any) => {
          return {
            path: pathFC(`/${gender}${paths?.categories}/${item?.slug}`),
            title: item?.name,
          };
        }),
      ]);
  }, [dataRawCategoriesBack?.[gender]]);

  return (
    <div className={s.footerMenu}>
      {footerMenuCategoryLink?.length > 0 && (
        <div className={s.menu}>
          {footerMenuCategoryLink.map((item: any, index: number) => {
            return (
              <Fragment key={index}>
                {lg ? (
                  item.title && (
                    <FooterMenuItem
                      item={item}
                      index={index}
                      categoriesBack={categoriesBack}
                      setActiveDrop={setActiveDrop}
                      activeDrop={activeDrop}
                    />
                  )
                ) : (
                  <FooterMenuItem
                    item={item}
                    index={index}
                    categoriesBack={categoriesBack}
                    setActiveDrop={setActiveDrop}
                    activeDrop={activeDrop}
                  />
                )}
              </Fragment>
            );
          })}
        </div>
      )}
    </div>
  );
};

type TFooterMenuItemProps = {
  item: any;
  index: any;
  categoriesBack: any;
  setActiveDrop: any;
  activeDrop: string;
};

const FooterMenuItem = ({
  item,
  index,
  categoriesBack,
  activeDrop,
  setActiveDrop,
}: TFooterMenuItemProps) => {
  const { t } = useTranslation();

  const lg = useMediaQuery({ maxWidth: breakpoints.lg });

  return (
    <div className={s.menuItem} key={index}>
      <div
        className={s.wrapperTitle}
        onClick={() =>
          activeDrop === item.title
            ? setActiveDrop(() => "")
            : setActiveDrop(() => item.title)
        }
      >
        <span
          className={s.menuItemTitle}
          // onClick={() => handleonClickShow(index)}
        >
          {item.title ? t(item.title) : <span>&nbsp;</span>}
        </span>
        {lg && (
          <span
            dangerouslySetInnerHTML={{ __html: arrowSvg }}
            className={
              activeDrop === item.title ? s.arrowActiveSvg : s.arrowSvg
            }
          />
        )}
      </div>
      {item?.items?.length > 0 && (
        <ul
          className={`${
            lg
              ? activeDrop === item.title
                ? s.linkMenuActive
                : s.linkMenuNotActive
              : s.linkMenu
          } `}
        >
          {t(item.title) === t("footer.menuFirst.title") ? (
            <>
              {categoriesBack.length > 0 &&
                categoriesBack.map((linkMenu: any, index: number) => {
                  const sublinkMenu = linkMenu?.additionalPath || null;
                  return (
                    <li
                      className={`${s.linkMenuItem} ${
                        sublinkMenu?.title && sublinkMenu?.path
                          ? s.isSublinkMenu
                          : ""
                      }`}
                      key={index}
                    >
                      <NavLink
                        to={linkMenu.path}
                        className={`${s.linkTitle} ${
                          linkMenu?.attention ? s.attention : ""
                        }`}
                      >
                        {t(linkMenu.title)}
                      </NavLink>
                      {sublinkMenu?.title && sublinkMenu?.path && (
                        <NavLink
                          to={sublinkMenu.path}
                          className={`${s.aditionalLinkTitle} ${s.linkTitle}`}
                        >
                          {t(sublinkMenu.title)}
                        </NavLink>
                      )}
                    </li>
                  );
                })}
            </>
          ) : (
            <></>
          )}
          {item?.items?.map((linkMenu: any, index: number) => {
            const sublinkMenu = linkMenu?.additionalPath || null;
            const linkMenuItem = (
              <li
                className={`${s.linkMenuItem} ${
                  sublinkMenu?.title && sublinkMenu?.path ? s.isSublinkMenu : ""
                }`}
              >
                <NavLink
                  to={linkMenu.path}
                  className={`${s.linkTitle} ${
                    linkMenu?.attention ? s.attention : ""
                  }`}
                >
                  {t(linkMenu.title)}
                </NavLink>
              </li>
            );
            const isItemNotNeed = (path: string) => {
              switch (path) {
                case pathFC(paths.mainWomen):
                  return item.title === "" ? linkMenuItem : <></>;
                case pathFC(paths.mainMen):
                  return item.title === "" ? linkMenuItem : <></>;
                case pathFC(paths.mainKids):
                  return item.title === "" ? linkMenuItem : <></>;
                default:
                  return linkMenuItem;
              }
            };

            return (
              <Fragment key={index}>
                {lg ? linkMenuItem : isItemNotNeed(linkMenu.path)}{" "}
              </Fragment>
            );
          })}
        </ul>
      )}
    </div>
  );
};
export default FooterMenu;
